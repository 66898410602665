export const Routing = {
  BusWorking: `/`,
  NewBooking: `/booking`,
  Setting: `/setting`,
  Reservation: `/reservation`,
  EditBus:`/setting/bus/edit/:id`,
  AddBus:`/setting/bus/create`,
  EditCity:`/setting/city/edit/:id`,
  AddCity:`/setting/city/create`,
  EditAddress: `/setting/address/edit/:id`,
  AddAddress: `/setting/address/create`,
  EditReservation:`/reservation/edit/:id`,
  ViewReservation:`/reservation/view/:id`,
  BusManagement:`/bus-management`,
  RouteDetail:`/route`,
  BoardingPoint:`/boarding-point`,
  DropingPoint:`/droping-point`,
  Gallary:`/gallary`,
  ChartOpen:`/chart`,
  Login: `/login`,
}