
import './App.css';
import AppRouting from './Shared/AppRouting';
import { Suspense } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import portalTheme from './Themes/App.thems';
import "react-toastify/dist/ReactToastify.css";
import "./Accests/Css/style.css";




function App() {
  return (
    <Suspense>
      <ThemeProvider theme={portalTheme}>

      <AppRouting />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
