// import EditNoteIcon from "@mui/icons-material/EditNote";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, IconButton, MenuItem, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import portalTheme from "../Themes/App.thems";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { SampleLogo } from "../Accests/Images";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import RouteIcon from "@mui/icons-material/Route";
import StartIcon from "@mui/icons-material/Start";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CollectionsIcon from "@mui/icons-material/Collections";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles(() => ({
  Navigation: {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    width: 260,
    zIndex: 1001,
    boxShadow: " inset -1px 0px 0px #DFE5F1",
    transition: "all 0.3s ease-in-out",
    // [portalTheme.breakpoints.down("md")]: {
    //   paddingTop: 0,
    //   zIndex: 5,
    // },
    "&.sidebar": {
      backgroundColor: [portalTheme.palette.primary.light],
      "& .hive-logo": {
        // padding: "15px",
        height: "auto",
        width: "160px",
        // borderRadius: "50%",
        margin: "10px",
        // padding: "35px 72px 0px 62px",
      },
      "& .sideBar-arrow": {
        top: "23px",
        position: "fixed",
        left: "245px",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        width: "30px",
      },
      // [portalTheme.breakpoints.down("md")]: {
      //   "& .sideBar-arrow": {
      //     left: "10px",
      //     cursor: "pointer",
      //     transform: " rotate(180deg)",
      //     transition: "all 0.3s ease-in-out",
      //   },
      // },
      "& .main-navigation": {
        overflow: "auto",
        maxHeight: "80%",
        height: "100%",
        // minHeight:"80%",
        "& .nav-link": {
          display: "block",
          padding: 0,
          "& .nav-item": {
            position: "relative",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            fontSize: 15,
            lineHeight: "19px",
            padding: "12px 12px",
            // color: [portalTheme.palette.secondary.light],
            "& .active-div": {
              background: "#1C3A66",
              width: "4px",
              height: "24px",
              position: "fixed",
              display: "none",
            },
            "& .nav-icon": {
              minWidth: 22,
              width: 22,
              height: 22,
              padding: "0px 19px 0 33px",
            },
            "& > span": {
              display: "inline-block",
              marginRight: 8,
              color: "#000",
              fontWeight: " 500 !important",
              fontSize: "16px",
              lineHeight: "24px",
            },
          },
        },
      },
    },
  },
}));

export const menus = [
  {
    image: NoteAddIcon,
    lable: "Booking",
    value: "booking",
  },
  {
    // image: EditNoteIcon,
    image: NoteAddIcon,
    lable: "Reservation",
    value: "reservation",
  },
  {
    image: SettingsIcon,
    lable: "Setting",
    value: "setting",
  },
  {
    image: DirectionsBusFilledIcon,
    lable: "Bus Manage",
    value: "bus-management",
  },
  {
    image: RouteIcon,
    lable: "Route Detail",
    value: "route",
  },
  {
    image: StartIcon,
    lable: "Boarding Point",
    value: "boarding-point",
  },
  {
    image: TrendingDownIcon,
    lable: "Droping Point",
    value: "droping-point",
  },
  {
    image: CollectionsIcon,
    lable: "Gallary",
    value: "gallary",
  },
  {
    image: StackedLineChartIcon,
    lable: "Chart Open",
    value: "chart",
  },
];

const SideBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [indexs, setindexs] = useState();

  const coursesPage = (menu, index) => {
    setindexs(index);
    if (menu.value) {
      navigate(`/${menu.value}`);
      setOpen(false);
    }
  };
  const isSmallScreen = useMediaQuery(portalTheme.breakpoints.down("md"));

  const toggleSidebar = () => {

    if (document.body.classList.contains("show-sidebar")) {
      document.body.classList.remove("show-sidebar");
      //   dispatch(getSidebarFailed());
    } else {
      document.body.classList.add("show-sidebar");
      // dispatch(getSidebarSuccess());
    }
  };

  useEffect(() => {
    if (
      (!document.body.classList.contains("show-sidebar") && isSmallScreen) ||
      (document.body.classList.contains("show-sidebar") && !isSmallScreen)
    ) {
      toggleSidebar();
    }
  }, [isSmallScreen]);



  // const location = useLocation();

  let Token = JSON.parse(localStorage.getItem("busToken"))

  useEffect(()=>{
    console.log(Token)
    if(Token !== "jhvjhvjvjhgdmnsmnsjdueucj54545dahhfe1c423dfd@@dd"){
      navigate("/login", { replace: true });
    }
  },[Token]);

  const handleLogout = () => {
    localStorage.removeItem("busToken");
    navigate("/login", { replace: true });
  }

  return (
    <aside className={`sidebar ${classes.Navigation}`}>
      <div className="d-flex justify-content-between mt-2 ">
        <img className="hive-logo" src={SampleLogo} alt="Logo" />
        <div className="px-2">
          <IconButton
            onClick={toggleSidebar}
            sx={{
              backgroundColor: "#049DD7",
              color: "#ffffff",
              ":hover": { backgroundColor: "#049DD7" },
            }}
          >
            {document.body.classList.contains("show-sidebar") ? 
            <MenuOpenOutlinedIcon /> :
            <ArrowForwardIcon />}
          </IconButton>
        </div>
      </div>
      <ul className="main-navigation">
        {menus?.map((menu, index) => {
          return (
            <React.Fragment key={menu.lable}>
              <MenuItem
                className={`nav-link "open"`}
                key={menu.id}
                // onClick={() => coursesPage(menu, index)}
              >
                <NavLink
                  style={{
                    pointerEvents: menu.value ? "auto" : "none",
                  }}
                  to={`/${menu.value}`}
                  //   onClick={clickSidebar}
                  // key={menu.value}
                  className={({ isActive }) =>
                    "nav-item" + (isActive ? " active" : "")
                  }
                  title={menu.lable}
                >
                  <menu.image sx={{ color: "#049DD7!important", mr: 1 }} />

                  <span>{t(menu.lable)}</span>
                </NavLink>
              </MenuItem>
            </React.Fragment>
          );
        })}
        <Button
        className="comman-button mx-2"
        sx={{width:"150px"}}
        onClick={handleLogout}
        >
          Logout
        </Button>
      </ul>
    </aside>
  );
};

export default SideBar;
