import { CircularProgress } from "@mui/material";
import React from "react";



const Spinner = ({ color = "primary" }) => {
  return (
    <div className="loader-wrapper">
      <CircularProgress color={"primary"} sx={{position:"fixed", top:"50%", left:"55%"}} thickness={2} size={50} />
    </div>
  );
};

export default Spinner;



